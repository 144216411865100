import { createI18n } from 'vue-i18n'

export const supportedLanguages = ['en', 'de']

const messages = Object.fromEntries(
  Object.entries(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    import.meta.glob<{ default: any }>('./locales/*.json', { eager: true }))
    .map(([key, value]): [string, Record<string, any>] => [key.slice(10, -5), filterEmptyTranslations(value.default)])
    .filter(([key]) => isLanguageSupported(key)),
)

function filterEmptyTranslations(localeModule: Record<string, any>): Record<string, any> {
  // not working now
  // for (const key of Object.keys(localeModule)) {
  //   if (!localeModule[key].source)
  //     delete localeModule[key]
  // }

  return localeModule
}

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD', notation: 'standard', minimumFractionDigits: 0, maximumFractionDigits: 2,
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent', useGrouping: false,
    },
  },
  'sk-SK': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    decimal: {
      style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 2,
    },
    percent: {
      style: 'percent', useGrouping: false,
    },
  },
}

export function localeTitle(locale: string): string {
  switch (locale) {
    case 'en':
      return 'English'
    case 'sk':
      return 'Slovensky'
    case 'pl':
      return 'Polski'
    case 'cs':
      return 'Česky'
    case 'hu':
      return 'Magyar'
    case 'de':
      return 'Deutsch'
    case 'se':
      return 'Svenska'
    default:
      return locale
  }
}

export function localeFlag(locale: string): string {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (locale) {
    case 'en':
      return 'gb'
    default:
      return locale
  }
}

export default createI18n({
  legacy: false,
  locale: parseDefaultLocale(),
  fallbackLocale: supportedLanguages[0],
  messages,

  // @ts-expect-error: Some missmatch in types, it should be fine
  numberFormats,
  fallbackWarn: false,
  missingWarn: false,
})

function parseDefaultLocale(): string {
  const browserLanguage = window.navigator.language.split('-')[0]

  return isLanguageSupported(browserLanguage) ? browserLanguage : supportedLanguages[0]
}

export function isLanguageSupported(locale: string): boolean {
  return supportedLanguages.includes(locale)
}
